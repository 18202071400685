import React from 'react';
import PropTypes from 'prop-types';
import BlockContent from '@sanity/block-content-to-react';
import { format, parseISO } from 'date-fns';
import styles from './legalContent.module.scss';

export default function Content({ title, updatedAt, content }) {
  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const { slug = {} } = mark
        const href = `/${slug.current}`
        return <a href={href}>{children}</a>
      },
      link: ({ mark, children }) => {
        // Read https://css-tricks.com/use-target_blank/
        const { blank, href } = mark
        return blank ?
          <a href={href} target="_blank" rel="noopener">{children}</a>
          : <a href={href}>{children}</a>
      }
    }
  }
  return (
    <main className={styles.container}>
      <h1>{title}</h1>
      <div className={styles.updatedAt}>{`Updated on ${format(
        parseISO(updatedAt),
        'MMMM do yyyy'
      )}`}</div>
      <div style={{ paddingBottom: '40px' }}>
        <BlockContent blocks={content} serializers={serializers}/>
      </div>
    </main>
  );
}



