import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import Content from './Content';
import useWindowWidth from '../../hooks/useWindowWidth';
import BackArrowButton from '../../components/BackArrowButton';
import FeedbackButton from '../../components/FeedbackButton';
import XupFooter from '../../components/XupFooter';
import XupWordmark from '../../svg/XupBetaWordmark';
import styles from './legal.module.scss';

function Legal({ documents, document = {} }) {
  let width = useWindowWidth();

  return (
    <>
      <aside className={styles.aside}>
        <BackArrowButton />
        <div className={styles.container}>
          <XupWordmark className={styles.logo} />
          <section className={styles.title}>
            <h1 className={cx(styles.h1, 'hero')}>Legal Shit</h1>
            <p className={styles.p}>
              {documents.map(doc => (
                <Link
                  to={doc.path}
                  className={cx({
                    [styles.activeLink]: doc.title === document.title,
                  })}
                >
                  {doc.title}
                </Link>
              ))}
            </p>
          </section>
          <section className={styles.feedbackContainer}>
            <FeedbackButton />
          </section>
        </div>
        {width > 1024 && <XupFooter />}
      </aside>
      <Content
        content={document._rawText}
        title={document.title}
        updatedAt={document._updatedAt}
      />
    </>
  );
}

export default Legal;
